<template>
  <div style="width: 100vw;height: 100vh;display: flex;justify-content: center;align-items: center">
    <el-button type="primary" @click="login">授权登录</el-button>
  </div>
</template>
<script>
// import { server } from "@/utils/request";
import app from '@/config/app';
import Vue from 'vue';
import httpRequest from '@/utils/network/request';

export default {
  data () {
    return {
      clientId: {
        weloveTest: 36,
        welove: 37,
        sweetTest: 38,
        sweet: 39,
        sweetusTest: 44,
        sweetus: 45,
        cubbilyTest: 48,
        cubbily: 49
      }
    };
  },
  methods: {
    login () {
      const redirect_uri = `${app.apiUrl}${app.apiUrl.endsWith('/manage') ? '':'/manage'}/callback`
      window.location = `https://sso.welove-inc.com/oauth/authorize?response_type=code&client_id=${this.clientId[app.serviceEnv]}&redirect_uri=${redirect_uri}`;
    }
  },
  mounted: function () {},
  created () {
    // if (this.$route.query.code) {
    //   httpRequest.get('/callback', {
    //     code: this.$route.query.code
    //   })
    //     .then(res=>{})
    //     .catch(error=>{})
    // }
    const vm = Vue.prototype;
    const tokenStr = vm.$cookie.get('accessToken');
    console.log('11111111', tokenStr);
    if (tokenStr) {
      this.$router.push('/home');
    }
  }
};
</script>
